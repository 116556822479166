import axios from 'axios';
import {AUTH_TOKEN } from "../Utils/constants";


const headers = {
    'Content-Type': 'application/json;charset=UTF-8'
};


export const USER_MANAGEMENT_API_SERVER_BASE_URL = process.env.REACT_APP_USER_MANAGEMENT_API_SERVER_HOST + '/api/user-management/v0';
// 'http://axon-rc.eastus.cloudapp.azure.com' + '/api/user-management/v0';
// process.env.REACT_APP_USER_MANAGEMENT_API_SERVER_HOST + '/api/user-management/v0';
// 'http://10.9.9.5:3001/api/user-management/v0'


export const LEARNING_API_SERVER_BASE_URL = process.env.REACT_APP_LEARNING_SERVER_HOST + '/api/learning/v1';
// 'http://axon-rc.eastus.cloudapp.azure.com/api/learning/v1'
// process.env.REACT_APP_LEARNING_SERVER_HOST + '/api/learning/v1';
// 'http://10.9.9.5:3002/api/learning/v1'


export const RABBITMQ_BASE_URL = process.env.REACT_APP_RABBITMQ_BASE_URL;
// 'ws://40.117.58.44:15674/ws';
// process.env.REACT_APP_RABBITMQ_BASE_URL;


export const UserManagement  = axios.create({baseURL: USER_MANAGEMENT_API_SERVER_BASE_URL, headers});
export const Learning = axios.create({baseURL:  LEARNING_API_SERVER_BASE_URL, headers });


UserManagement.interceptors.request.use(function (config) {
    const token = localStorage.getItem(AUTH_TOKEN);
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });


  Learning.interceptors.request.use(function (config) {
    const token = localStorage.getItem(AUTH_TOKEN);
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

