import React, { Component } from 'react';

class Alert extends Component{
    render(){
        return(
            <div className="col-md-4 col-sm-4 position-absolute alert alert-dismissible mx-auto mnBgDark" style={{left:'33%', marginTop: '60px'}} role="alert">
                <p className="text-danger text-center m-0">{this.props.title}</p>
            </div>
        )
       
    }
}

export default Alert;
