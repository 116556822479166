import React, { Component } from "react";
import "./index.scss";
import { withRouter } from "react-router";
import RabbitMQQuestionsService from "../../../services/rabbitmq_services";
class WelcomeScreen extends Component {

  componentDidMount() {
    const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
    rabbitMQService.connectToRabbitMQ();
  }

  render() {
    return (
      <div>
        <div className="top-background-image">
          <img
            height="40"
            alt="axon"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <div className="p-3">
          <div className="container align-middle">
          <p className="text-center business mt-3 mb-1"><b>Welcome</b></p>
              <p className="text-center business  mb-4"><b> to</b></p>

              <p className="text-center business mb-1"><b> Cyber Risk Summit</b></p>

            <p className="text-center welcome mb-5">
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s */}
              {/* To the Philadelphia 2021 Cyber Risk Summit<br></br> */}
            Philadelphia Jun 1<sup>st</sup>, 2022 <br></br>
             
            </p>
            <p className="text-center business m-0"><b>Interactive Business</b></p>
            <p className="text-center business"><b>
              {/* It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum. */}
               Interruption Scenario</b>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WelcomeScreen);
