import React from "react";
import "./index.scss";
import { withRouter } from "react-router";

function AxonFooter({ location }) {
  return (
    <footer
    //  className={`${location.pathname === '/splash' ? 'no-footer' : 'footer'}`}
     >
      {/* <div className="container d-flex justify-content-between align-items-center">
        
        <img className="mrc" alt="netdiligence"
          src={process.env.PUBLIC_URL + '/images/Cullen.png'} />
          <img alt="infiniqo"
        className="cra"
        src={process.env.PUBLIC_URL + '/images/CRA.png'} />
        <img className="netdiligence-logo" alt="netdiligence"
          src={process.env.PUBLIC_URL + '/images/netdiligence_footer_logo.png'} />
      </div> */}



      <div className="container d-flex justify-content-between align-items-center">
        
        {/* <img className="mrc" alt="netdiligence"
          src={process.env.PUBLIC_URL + '/images/Cullen.png'} /> */}
          {/* <img alt="infiniqo"
        className="cra"
        src={process.env.PUBLIC_URL + '/images/CRA.png'} /> */}
        <div></div>
        <img className="netdiligence-logo" alt="netdiligence"
          src={process.env.PUBLIC_URL + '/images/netdiligence_footer_logo.png'} />
          <div></div>
      </div>
    </footer>
  );
}

export default withRouter(AxonFooter);
