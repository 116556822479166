import Q,{} from 'q';
import { UserManagement } from './../../config/api';

export const validateUser =(userName, password)=>{
    let def = Q.defer();
    UserManagement.post('/login',{'email':userName, 'password': password})
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

export const registerUser =(playerId, userData)=>{
    let def = Q.defer();
    UserManagement.post(`/signup/${playerId}`, userData)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

export const forgotPassword =(data)=>{
    let def = Q.defer();
    UserManagement.post('passwords/forgot', data)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

export const resetPassword =(token, data)=>{
    let def = Q.defer();
    UserManagement.post(`passwords/reset/${token}`, data)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

export const getUserDetails =(playerId)=>{
    let def = Q.defer();
    UserManagement.get(`players/${playerId}`)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};





