import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import './../assets/stylesheets/normalize.css';
import './../assets/stylesheets/main.css';

import Logo from './../assets/images/axon-logo.png';

import Alert from './../components/alert';
import { validateUser } from './../services/domain_services/userManagement';
import { Link } from 'react-router-dom'

class Login extends Component {
    constructor(props){
        super(props)
        this.state={
            userName: '',
            password: '',
            showWarning: false,
            isUserLoggedIn: false,
            alertMessage: ''
        }
    }
    componentDidMount(){
        if(localStorage.getItem('accessToken')){
            this.setState({
                isUserLoggedIn: true
            })
        }
    }

    handleUserName=(e)=>{
        this.setState({
            userName: e.target.value
        })  
        this.dismissWarning() 
    }

    handlePassword=(e)=>{
        this.setState({
            password: e.target.value
        })
        this.dismissWarning()
    }

    dismissWarning=()=>{
        if(this.state.showWarning)  {
            this.setState({
                showWarning: false
            })
        }
    }

    verifyUser=(e)=>{
        e.preventDefault();
        if(this.state.userName==='' || this.state.password===''){
            this.setState({
                showWarning: true,
                alertMessage: '* All the fields are required'
            })
        }
        else {
            validateUser(this.state.userName, this.state.password)
            .then(data =>{
                if(data.status===200){
                    localStorage.setItem('accessToken', data.data.token);
                    localStorage.setItem('playerId', data.data.playerId);
                    this.setState({
                        isUserLoggedIn: true
                    })
                }
            })   
            .catch(error=>{
                this.setState({
                    showWarning: true,
                    alertMessage: '* Invalid credentials'
                })
                setTimeout(function() { this.setState({showWarning: false}); }.bind(this), 3000)
            })        
        }
    }
    
    render() {
        //direct navaigation to dashboard if already logged in
        if(this.state.isUserLoggedIn) {
            return(
                <Redirect to="/dashboard"/>
            )
        }
        return(
            <div className="mainWrap darkTheme" style={{ height: window.innerHeight}} > 
                <div className="formSignin">
                    <img className="logo" src={Logo} alt="" width="107"/>
                    <div className="formFields">
                        <form>
                            <div className="form-group">
                                <label htmlFor="userName">User Name</label>
                                <input type="text" className="form-control" autoComplete="off" id="userName" aria-describedby="emailHelp" value={this.state.userName} onChange={this.handleUserName}/>  
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <input type="password" autoComplete="off" className="form-control" id="exampleInputPassword1" value={this.state.password} onChange={this.handlePassword}/>
                            </div>
                            <div className="row">
                                <Link to="/forgot_password" className="btn btn-link newLink">Forgot password?</Link>
                            </div>
                            <div className="row">
                                <div className="col mb-4">
                                    <button type="submit" className="float-right btn btn-primary btnStyle1" onClick={this.verifyUser}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    this.state.showWarning && <Alert title={this.state.alertMessage}/>
                } 
            </div>
        )} 
    }

export default Login;



