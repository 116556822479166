import React, { Component } from 'react';

import './../assets/stylesheets/normalize.css';
import './../assets/stylesheets/main.css';

import Logo from './../assets/images/axon-logo.png';

import Alert from './../components/alert';
import { forgotPassword } from './../services/domain_services/userManagement';
import { Link } from 'react-router-dom'

class ForgotPasswordView extends Component {
    constructor(props){
        super(props)
        this.state={
            email: '',
            showWarning: false,
            isUserLoggedIn: false,
            alertMessage: ''
        }
    }
  
    handleEmail=(e)=>{
        this.setState({
            email: e.target.value
        })  
        this.dismissWarning() 
    }

    dismissWarning=()=>{
        if(this.state.showWarning)  {
            this.setState({
                showWarning: false
            })
        }
    }
    
    clearWarn=()=>{
        setTimeout(()=>{ 
            this.setState({
                showWarning: false
            })
        }, 3000);
    }
    
    submitEmail=(e)=>{
        e.preventDefault();
        if(this.state.email===""){
            this.setState({
                showWarning: true,
                alertMessage: '* Email id required'
            })
            this.clearWarn()
        }
        else {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(mailformat.test(this.state.email)){
                let data = {
                    email: this.state.email
                }
                forgotPassword(data)
                .then(response =>{
                    // console.log(data)
                    if(response.status===200){
                        this.setState({
                            showWarning: true,
                            alertMessage:  response.data.message
                        })
                        this.clearWarn()
                    }
                })   
                .catch(error=>{
                    this.setState({
                        showWarning: true,
                        alertMessage: 'There is a problem in processing your request'
                    })
                    this.clearWarn()
                })
            }
            else {
                this.setState({
                    showWarning: true,
                    alertMessage: '* Please enter a valid email id'
                })
                this.clearWarn()
            }
        }  
    }

    render() {
        return(
            <div className="mainWrap darkTheme" style={{ height: window.innerHeight}} > 
                <div className="formSignin">
                    <img className="logo" src={Logo} alt="" width="107"/>
                    <div className="formFields">
                        <form>
                            <p className="text-white">
                                <strong>Forgot user name or password?</strong>
                            </p>
                            <p className="text-white" style={{ fontSize: 13}}>Enter the email address associated with your membership. We’ll send you an email with a link for resetting your password.</p>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" autoComplete="off" id="email" value={this.state.email} onChange={this.handleEmail}/>  
                            </div>
                            
                            <div className="row">
                                <Link to="/login" className="btn btn-link newLink">Back to login?</Link>
                            </div>
                            <div className="row">
                                <div className="col mb-4">
                                    <button type="submit" className="float-right btn btn-primary btnStyle1" onClick={this.submitEmail}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    this.state.showWarning && <Alert title={this.state.alertMessage}/>
                } 
            </div>
        )} 
    }

export default ForgotPasswordView;



