export const AUTH_TOKEN = "AUTH_TOKEN";
export const PRIMARY_COLOR = "#115D5D";
export const SECONDARY_COLOR = "#F78E38";
export const PARTICIPANT_ID = "participant_id";
export const CONFERENCE_ID = "CONFERENCE_ID";
export const USER_FULLNAME = "USER_FULLNAME";
export const USER_EMAIL = "USER_EMAIL";
export const PLAYER_SPG_ID = "PLAYER_SPG_ID";
export const LAST_PLAYED_NODE = "LAST_PLAYED_NODE";
export const NODE_INDEX = "NODE_INDEX";
export const LAST_QUESTION_ID = "LAST_QUESTION_ID";