import {  UserManagement, Learning  } from './../../config/api';
import Q,{} from 'q';


export const newUser =(data)=>{
    let def = Q.defer();
    UserManagement.post('/participants',data)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};



export const participantProfileDetails =(id)=>{
    let def = Q.defer();
    UserManagement.get(`/participants/${id}`)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

export const   updateProfileDetails =(id, data)=>{
    let def = Q.defer();
    UserManagement.put(`/participants/${id}`, data)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};


export const playerSession =(status)=>{
    let def = Q.defer();
    UserManagement.get(`/participants/sessions?status=${status}`)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};



export const questionAnswered =(participant_id, player_spg_id, node_index, data)=>{
    let def = Q.defer();
    Learning.post(`/participant/${participant_id}/spg/${player_spg_id}/nodes/${node_index}/choice`,data)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};



export const lastNodeQuestion =(participant_id, conference_id)=>{
    let def = Q.defer();
    Learning.get(`/participant/${participant_id}/conference_id/${conference_id}/node`)
        .then(response => {
            def.resolve(response)
        })
        .catch(error=> {
            def.reject(error)});
    return def.promise;
};

