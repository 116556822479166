import React, { Component } from "react";
import "./index.scss";
import ReactCountdownClock from "react-countdown-clock";
import { withRouter } from "react-router";
import { questionAnswered } from "../../../services/learning_services/learningservice";
import { LAST_PLAYED_NODE, PARTICIPANT_ID, PLAYER_SPG_ID } from "../../../Utils/constants";
import RabbitMQQuestionsService, { NODE_TYPES } from "../../../services/rabbitmq_services";
import Modal from "react-modal";
import ConnectionStatus from "../../../components/ConnectionStatus";

const TIMESTAMP = "TIMESTAMP";

class QuestionsScreen extends Component {
  state = {
    timerCompleted: false,
    selectedOption: -1,
    choices: [],
    time_to_answer: 0,
    player_spg_id: null,
    node_index: null,
    showModal: false,
    modalImage: ""
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate", prevProps, this.props);

    if (
      this.props.location.state &&
      prevProps &&
      prevProps.location &&
      prevProps.location.state
    ) {
      if (this.props.location.state.type === NODE_TYPES.QUESTION) {
        if (this.props.location.state.id != prevProps.location.state.id) {
          const {
            title,
            choices,
            node_index,
            player_spg_id,
            time_to_answer,
            timestamp
          } = this.props.location.state;
          localStorage.setItem(TIMESTAMP, timestamp.toString());
          this.setState(
            {
              title,
              choices,
              node_index,
              player_spg_id,
              time_to_answer,
              showModal: false
            },
            () => {
              this.onPageReload();
            }
          );
        }
      } else if (this.props.location.state.type === NODE_TYPES.INJECT) {
        console.log(this.props.location.state.illustration_apa_url !== prevProps.location.state.illustration_apa_url);
        if (this.props.location.state.illustration_apa_url !== prevProps.location.state.illustration_apa_url) {
          console.log("Show image in question screen");
          this.setState({
            time_to_answer: 60 * 60 * 2,
            modalImage: this.props.location.state.illustration_apa_url,
            showModal: true
          });
        }
      }
    }
  }

  componentDidMount() {
    const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
    rabbitMQService.connectToRabbitMQ();

    if (this.props.location.state) {
      const {
        title,
        choices,
        node_index,
        player_spg_id,
        time_to_answer,
        timestamp,
        illustration_apa_url,
        type
      } = this.props.location.state;
      console.log(type, NODE_TYPES.QUESTION);
      if (type === NODE_TYPES.QUESTION) {
        const lastNode = localStorage.getItem(LAST_PLAYED_NODE);
        const player_spg_id_recent = localStorage.getItem(PLAYER_SPG_ID);
        // debugger;
        if (lastNode == node_index
            && (player_spg_id && (player_spg_id === player_spg_id_recent))) {
          console.log("Going to welcome screen", lastNode, node_index);
          
          this.props.history.push("/participant/welcome");
        }
        localStorage.setItem(TIMESTAMP, timestamp.toString());
        this.setState(
          {
            title,
            choices,
            node_index,
            player_spg_id,
            time_to_answer,
            showModal: false
          },
          () => {
            this.onPageReload();
          }
        );
      } else if (type === NODE_TYPES.INJECT) {
        console.log("Show image in question screen");
        this.setState({
          time_to_answer: 60 * 60 * 2,
          modalImage: illustration_apa_url,
          showModal: true
        });
      }
    } else {
      console.log("Going to welcome screen", this.props.location.state);
      
      this.props.history.push("/participant/welcome");
    }
  }

  onPageReload() {
    const countdownTarget = localStorage.getItem(TIMESTAMP);
    try {
      const timestamp = JSON.parse(countdownTarget);
      const remainingTime =
        timestamp + this.state.time_to_answer * 1000 - Date.now();
      if (remainingTime > 0) {
        this.setState({
          time_to_answer: remainingTime / 1000
        });
      } else {
        console.log("Else ");
        this.props.history.push("/participant/welcome");
      }
    } catch (error) {
      console.log(error);
      this.props.history.push("/participant/welcome");
    }
  }

  onTimerComplete = () => {
    this.setState({
      timerCompleted: true
    });
    setTimeout(() => {
      this.props.history.push("/participant/time-out-cancel");
    }, 1000);
  };

  getOptionAlphabet = optionIndex => {
    const capitalAlphabetCharacterStarting = 65;
    return String.fromCharCode(capitalAlphabetCharacterStarting + optionIndex);
  };

  onOptionSelected = optionIndex => {
    this.setState({
      selectedOption: optionIndex
    });
    const { choices, player_spg_id, node_index } = this.state;
    const { id } = this.props.location.state;

    const participant_id = localStorage.getItem(PARTICIPANT_ID);
    questionAnswered(participant_id, player_spg_id, node_index, {
      choice_id: choices[optionIndex].id
    })
      .then(response => {
        localStorage.setItem(LAST_PLAYED_NODE, node_index);
        localStorage.setItem(PLAYER_SPG_ID, player_spg_id);
        // localStorage.setItem(LAST_QUESTION_ID, id);
        this.props.history.push({
          pathname: '/participant/thank-you',
          state: {
            from: 'question'
          }
        });
      })
      .catch(error => console.log(error));
  };

  closeModal = () => {
    console.log(true);
    this.setState({
      showModal: false
    });
  };

  render() {
    return (
      <div>
        <div className="timer-container position-relative">
          <div className="question-container one">
            <p className="ques">Question:</p>
            <p className="text-white title">{this.state.title}</p>
          </div>
          <div
            className={`position-absolute countdown-container ${
              this.state.timerCompleted ? "countdown-timer-complete" : ""
            }`}
          >
            <ReactCountdownClock
              seconds={this.state.time_to_answer}
              color="#F78E38"
              alpha={0.9}
              size={150}
              weight={25}
              onComplete={this.onTimerComplete}
            />
          </div>
        </div>
        <div className="options-container">
          {this.state.choices.map((option, index) => (
            <div
              onClick={() => this.onOptionSelected(index)}
              key={index}
              className={`single-option-container ${
                index === this.state.selectedOption ? "selected-option" : ""
              }`}
            >
              <p className="option-name">{this.getOptionAlphabet(index)}</p>
              <p className="m-0">{option.label}</p>
            </div>
          ))}
        </div>

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal}
          contentLabel=""
          ariaHideApp={false}
          style={{
            content: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              padding: `0 20px 20px`
            }
          }}
        >
          <div>
            <div className="d-flex justify-content-end py-3">
              <ConnectionStatus />
            </div>
            <img className="modal-image" src={this.state.modalImage} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(QuestionsScreen);
