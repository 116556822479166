import React, { Component } from "react";
import "./index.scss";
import { Icon, Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import UserDetailsTextFields from "../../../components/UserDetailsTextFields";
import * as Yup from "yup";
import { participantProfileDetails } from "../../../services/learning_services/learningservice";
import { updateProfileDetails } from "../../../services/learning_services/learningservice";
import { PARTICIPANT_ID } from "../../../Utils/constants";
import RabbitMQQuestionsService from "../../../services/rabbitmq_services";

const classes = {
  button: {
    fontWeight: "bold",
    color: "#fff"
  }
};

const UserDetailsSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Minimum 2 characters required")
    .max(50, "Cannot exceed 50 characters")
    .required("Full name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  // role: Yup.string()
  //   .min(2, "Minimum 2 characters required")
  //   .max(50, "Cannot exceed 50 characters")
  //   .required("Role is required")
});

class UserProfileScreen extends Component {
  state = {
    isEdit: false,
    userDetails: {
      fullName: "",
      email: "",
      // role: ""
    }
  };


  componentDidMount() {

    const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
    rabbitMQService.connectToRabbitMQ();


    let participantId = localStorage.getItem("participant_id"); 
    participantProfileDetails(participantId)
      .then(response => {
        const { name: fullName, email
          // , designation: role
           } = response.data[0];
        this.setState({
          userDetails: { fullName, email
            // , role 
          }
        })
      })   
      .catch(error=>console.log(error));
    };
  


    updateProfileDetails() {
      let data = {
        name: '',
        email: '',
        // designation: ''
      }
      updateProfileDetails(data)
      .then(response =>{
        // this.setState({
        //   userDetails: 
        // })
      })   
      .catch(error=>console.log(error));
    };
    
    userDetailsSubmit = values => {
      const { fullName: name, email
        // , role: designation
       } = values;
      this.setState({ isEdit: false });
      updateProfileDetails(localStorage.getItem(PARTICIPANT_ID),{name, email
        // , designation
      })
        .then(response =>{
          // this.setState({
          //   userDetails: 
          // })
        })   
        .catch(error=>console.log(error));
    };

  onEdit = () => {
    const { isEdit } = this.state;
    this.setState({ isEdit: !isEdit });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="profile-top-container">
          <Icon fontSize="large">person_outline</Icon>
          <h3 className="m-0 text-capitalize">{this.state.userDetails.fullName}</h3>
          {/* <small className="text-capitalize">{this.state.userDetails.role}</small> */}
        </div>

        <div className="profile-fields-container">
          <Formik
            enableReinitialize
            initialValues={this.state.userDetails}
            validationSchema={UserDetailsSchema}
            onSubmit={this.userDetailsSubmit}
          >
            {({ errors, touched, resetForm }) => (
              <div>
                <p
                  className="text-right edit"
                  onClick={() => {
                    resetForm(this.state.userDetails);
                    this.onEdit();
                  }}
                >
                  {this.state.isEdit ? "Cancel" : "Edit"}
                </p>
                <Form
                  noValidate
                  className="container px-4 d-flex flex-column mt-4"
                >
                  <UserDetailsTextFields disabled={!this.state.isEdit} />

                  <Fab
                    variant="extended"
                    className={`my-4 ${classes.button}`}
                    size="large"
                    color="primary"
                    disabled={!this.state.isEdit}
                    aria-label="add"
                    type="submit"
                  >
                    Save
                  </Fab>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withStyles(classes)(UserProfileScreen);
