import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
// import SplashScreen from "./pages/UnauthorizedRoutes/SplashScreen";
import UserDetailsScreen from "./pages/UnauthorizedRoutes/UserDetailsScreen";
import { AUTH_TOKEN, PRIMARY_COLOR, SECONDARY_COLOR } from "./Utils/constants";
import LogoutScreen from "./pages/UnauthorizedRoutes/LogoutScreen";
import WelcomeScreen from "./pages/AuthorizedRoutes/WelcomeScreen";
import AxonFooter from "./components/AxonFooter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import SignUp from "./pages/signUp";
import ResetView from "./pages/resetPassword.js";
import ForgotPasswordView from "./pages/forgotPassword.js";
import Login from "./pages/login";
import UserProfileScreen from "./pages/AuthorizedRoutes/UserProfileScreen";
import SideNavScreen from "./pages/AuthorizedRoutes/SideNavScreen";
import ThankYouScreen from "./pages/AuthorizedRoutes/ThankYouScreen";
import QuestionsScreen from "./pages/AuthorizedRoutes/QuestionsScreen";
import TimeOutCancelScreen from "./pages/AuthorizedRoutes/TimeOutCancelScreen";

const routes = [
  {
    path: "/participant",
    component: SideNavScreen,
    routes: [
      {
        path: "/participant/welcome",
        component: WelcomeScreen
      },
      {
        path: "/participant/userProfile",
        component: UserProfileScreen
      },
      {
        path: "/participant/question",
        component: QuestionsScreen
      },
      {
        path: "/participant/thank-you",
        component: ThankYouScreen
      },
      {
        path: "/participant/time-out-cancel",
        component: TimeOutCancelScreen
      }
    ]
  }
];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  }
});

export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props =>
        // pass the sub-routes down to keep nesting
        isAuthorized() ? (
          <route.component {...props} routes={route.routes} />
        ) : (
          <Redirect
            to={{
              pathname: "/join",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <div className="content">
          <Switch>
            <Route path="/signup/:playerId" component={SignUp} />
            <Route path="/password/reset/:token" component={ResetView} />
            <Route path="/forgot_password" component={ForgotPasswordView} />
            <Route path="/login" component={Login} />
            <Redirect exact from="/" to="/participant/welcome" />
            {/* <UnAuthorizedRoute exact path="/splash" component={SplashScreen} /> */}
            <UnAuthorizedRoute exact path="/join" component={UserDetailsScreen} />
            <Route exact path="/logout" component={LogoutScreen} />
            {/* <AuthorizedRoute exact path="/participant" component={SideNavScreen} />
            <AuthorizedRoute path="/welcome" component={WelcomeScreen} /> */}
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </div>
        <AxonFooter />
      </MuiThemeProvider>
    </Router>
  );
}

// function UnauthorizedRoute() {
//   return (

//   );
// }

function isAuthorized() {
  return localStorage.getItem(AUTH_TOKEN) ? true : false;
}

function UnAuthorizedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthorized() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/participant/welcome",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default App;
