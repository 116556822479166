import React, { Fragment, Component } from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

export default class UserDetailsTextFields extends Component {

  render() {
        return (
         
            <Fragment>
                <Field
                id="full-name"
                className="my-3"
                type="text"
                placeholder="Full name"
                name="fullName"
                component={TextField}
                margin="normal"
                disabled={this.props.disabled}
              />

              <Field
                id="company-name"
                className="my-3"
                type="email"
                placeholder="Company email"
                name="email"
                component={TextField}
                margin="normal"
                disabled={this.props.disabled}
              />

              {/* <Field
                id="company-role"
                className="my-3"
                type="text"
                placeholder="Company role"
                name="role"
                component={TextField}
                margin="normal"
                disabled={this.props.disabled}
              /> */}
            </Fragment>
        );
}
}