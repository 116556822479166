import React, { Component } from 'react';
import Logo from './../assets/images/axon-logo.png';
import Alert from './../components/alert';
import { registerUser, getUserDetails } from './../services/domain_services/userManagement';

class SignUp extends Component {
    constructor(props){
        super(props)
        this.state={
            email: '',
            password: '',
            confirmPassword: '',
            showWarning: false,
            playerId: props.match.params.playerId,
            proceedToLogin: false,
            alertMessage: ''
        }
    }

    componentWillMount(){
        getUserDetails(this.props.match.params.playerId)
        .then(response =>{
            // console.log(response)
            this.setState({
                email: response.data.email
            })
        })   
        .catch(error=>{
            console.log('error')
        })        
    }
   
    handlePassword=(e)=>{
        this.setState({
            password: e.target.value
        })
        this.dismissWarning()
    }

    handleConfirmPassword=(e)=>{
        this.setState({
            confirmPassword: e.target.value
        })
        this.dismissWarning()
    }

    dismissWarning=()=>{
        if(this.state.showWarning)  {
            this.setState({
                showWarning: false
            })
        }
    }

    verifyUser=(e)=>{
        console.log(this.state.password)
        console.log(this.state.confirmPassword)
        e.preventDefault();
        if(this.state.password==='' || this.state.confirmPassword===''){
            this.setState({
                showWarning: true,
                alertMessage: '* All the fields are required'
            })
        }
        else if(this.state.password!==this.state.confirmPassword){
            this.setState({
                showWarning: true,
                alertMessage: '* Password mismatch'
            })
        }
        else {
            this.registerUser()
        }
    }

    registerUser=(e)=>{
        const userData = {
            "password": this.state.password,
            "confirmPassword": this.state.confirmPassword
        }
        registerUser(this.state.playerId, userData)
        .then(response =>{
            console.log(response)
            this.setState({
                proceedToLogin: true,
                alertMessage: 'User signup successful'
            })
        })   
        .catch(error=>{
            this.setState({
                proceedToLogin: true,
                alertMessage: 'User already exists'
            })
        })        
    }
    
    renderSignUpView=()=>{
        console.log(!this.state.proceedToLogin)
        if(!this.state.proceedToLogin){
                return <div className="mainWrap darkTheme"> 
                            <div className="formSignin">
                                <img className="logo" src={Logo} alt="" width="107"/>
                                <div className="formFields">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="userName">Email</label>
                                            <input type="text" className="form-control" autoComplete="off" id="disabledUserName" aria-describedby="emailHelp" value={this.state.email} disabled/>  
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="userName">Password</label>
                                            <input type="password" className="form-control" autoComplete="off" id="password" aria-describedby="emailHelp" value={this.state.password} onChange={this.handlePassword}/>  
                                        </div>
                                        <div className="form-group mb-4">
                                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                            <input type="password" autoComplete="off" className="form-control" id="conf_password" value={this.state.confirmPassword} onChange={this.handleConfirmPassword}/>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-4">
                                                <button type="submit" className="float-right btn btn-primary btnStyle1" onClick={this.verifyUser}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.state.showWarning && <Alert className="mt-4" title={this.state.alertMessage}/>}
                            </div>
                        </div>

                    }
                else {
                    return <SuccessAlert message={this.state.alertMessage}/>
                }
            }
    render() {
        return(
            <div>
                {this.renderSignUpView()}
            </div>
        )} 
    }

    const SuccessAlert = (props) =>{
            return <div className="mainWrap" style={{ height: window.innerHeight}} > 
                        <div className="formSignin">
                            <div className="formFields pb-5">
                                <div className="text text-white">
                                    <div className="text-center"><i className="fa fa-check rounded border p-2" aria-hidden="true" style={{fontSize:'48px'}}></i></div>
                                    <div><h5 className="text text-white text-primary text-center p-2 mt-2">{props.message}</h5></div>
                                </div>  
                            </div>
                        </div>
                    </div>              
    }

export default SignUp;



