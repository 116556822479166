import React, { Component } from "react";
import { Fab, FormControlLabel, Radio, FormHelperText } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";
import { withStyles } from "@material-ui/styles";
import "./index.scss";
import { AUTH_TOKEN, PARTICIPANT_ID, CONFERENCE_ID, USER_FULLNAME, USER_EMAIL } from "../../../Utils/constants";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import UserDetailsTextFields from "../../../components/UserDetailsTextFields";
import { newUser, playerSession } from "../../../services/learning_services/learningservice";

const ParticipantDetailsSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Minimum 2 characters required")
    .max(50, "Cannot exceed 50 characters")
    .required("Full name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  // role: Yup.string()
  //   .min(2, "Minimum 2 characters required")
  //   .max(50, "Cannot exceed 50 characters")
  //   .required("Role is required"),
  session: Yup.string().required("Required")
});

const classes = {
  button: {
    fontWeight: "bold",
    color: "#fff"
  },
  formControlLabel: {
    border: `1px solid black`,
    borderRadius: 32,
    paddingRight: 24,
    paddingLeft: 8,
    marginBottom: 0
  }
};

class UserDetailsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      userDetails: {
        fullName: "",
        email: "",
        session: "infiniqo"
      }
    };
  }

  componentDidMount() {
    
 const fullName =   localStorage.getItem(USER_FULLNAME);
 const email =   localStorage.getItem(USER_EMAIL);
 if(fullName && email){
   const {
     userDetails
   } = this.state;
   const { session } = userDetails;
  this.setState({ userDetails: {
    fullName,
    email,
    session
  } });
 }
    playerSession('')
      .then(response => {
        const { data: sessions } = response;
        this.setState({ sessions });
        if (sessions.length === 1) {
          const {
            userDetails
          } = this.state;
          const { fullName, email } = userDetails;
          this.setState({
            userDetails: {
              fullName,
              email,
              session: "infiniqo"
            }
          });
        }
      })   
      .catch(error=>console.log(error));
  };

   
  userDetailsSubmit = (values, actions) => {
    if (actions) {
      const { fullName: name, email
        // , role: designation
        , session: last_conference_id } = values;
      const user = {
        name,
        email,
        // designation,
        last_conference_id
      };  
      
      newUser(user)
      .then(response =>{
         localStorage.setItem(AUTH_TOKEN, response.data.token);
         localStorage.setItem(PARTICIPANT_ID, response.data._id);
         localStorage.setItem(CONFERENCE_ID, last_conference_id);
         localStorage.setItem(USER_FULLNAME, name);
         localStorage.setItem(USER_EMAIL, email);
         this.props.history.push("/participant/welcome");
      })   
      .catch(error=>console.log(error));
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="top-background-image">
          <img
            alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <Formik
          initialValues={this.state.userDetails}
          enableReinitialize
          validationSchema={ParticipantDetailsSchema}
          onSubmit={(values, actions) => this.userDetailsSubmit(values, actions)}
        >
          {({ errors, touched }) => (
            <Form noValidate className="container px-4 d-flex flex-column mt-4">
              <UserDetailsTextFields/>
              {/* {this.state.sessions.length > 1 && (<div className="mt-3">
                <label>Select session</label>

                <Field name="session"
                 className="d-flex flex-row pl-2"
                 label="Session" component={RadioGroup}>
                  {
                    this.state.sessions.map(userSession =>
                      <FormControlLabel
                      className={`mb-2 ${classes.formControlLabel}`}
                      value={userSession}
                      control={<Radio color="secondary" />}
                      label={userSession}
                      key={userSession}
                    />)
                  }
                </Field>

                {errors.session && touched.session && <FormHelperText error>
                  Select a session
                </FormHelperText>}
              </div>)} */}

              <Fab
                variant="extended"
                className={`mt-4 ${classes.button}`}
                size="large"
                color="primary"
                aria-label="add"
                type="submit"
                onClick={this.userDetailsSubmit}
              >
                Join
              </Fab>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withStyles(classes)(UserDetailsScreen);