import React, { Component } from "react";
import "./index.scss";
import { withRouter} from "react-router";
import RabbitMQQuestionsService from "../../../services/rabbitmq_services";
// import { AUTH_TOKEN } from "../../../Utils/constants";

class TimeOutCancel extends Component {
  componentDidMount() {
    const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
    rabbitMQService.connectToRabbitMQ();

    this.renderRedirect();
  }

  renderRedirect = () => {
    // setTimeout(() => {
    //   localStorage.removeItem(AUTH_TOKEN);
    //   // return <Redirect to='/splash' />
    //   this.props.history.push('/splash');
    //   window.location.reload();
    // }, 10000);

    
  }

  componentWillUnmount() {
    // this.backListener();
  }

  render() {
    return (
      <div>
        {/* {this.renderRedirect()} */}
        <div className="top-background-image">
          <img alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <div className="time-out-container">
            <img height="100" className="mb-3"
            alt="tick-mark"
            src={process.env.PUBLIC_URL + "/images/Cancel.svg"}/>
            <p className="text-center">Session Expired.</p>
        </div>
      </div>
    );
  }
}

export default withRouter(TimeOutCancel);