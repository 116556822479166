import React, { Component } from 'react';
import Logo from './../assets/images/axon-logo.png';
import Alert from './../components/alert';
import { resetPassword } from './../services/domain_services/userManagement';

class ResetView extends Component {
    constructor(props){
        super(props)
        // console.log(props.match.params.token)
        this.state={
           password: '',
           confirmPassword: '',
           resetPasswordSuccess: false
        }
    }
    
    handlePassword1=(e)=>{
        this.setState({
            password: e.target.value
        })  
    }

    handlePassword2=(e)=>{
        this.setState({
            confirmPassword: e.target.value
        })  
    }
    
    clearWarn=()=>{
        setTimeout(()=>{ 
            this.setState({
                showWarning: false
            })
        }, 3000);
    }

    submitForm=(e)=>{
        e.preventDefault();
        if(this.state.password==='' || this.state.confirmPassword===''){
            this.setState({
                showWarning: true,
                alertMessage: '* All the fields are required'
            })
            this.clearWarn()
        }
        else if(this.state.password!==this.state.confirmPassword){
            this.setState({
                showWarning: true,
                alertMessage: '* Password mismatch'
            })
            this.clearWarn()
        }
        else {
            this.resetPassword()
        }
    }

    resetPassword=()=>{
        const data = {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
        const token = this.props.match.params.token;
        resetPassword(token, data)
        .then(response =>{
            // console.log(data)
            if(response.status===200){
                this.setState({
                    resetPasswordSuccess: true
                })
            }
        })   
        .catch(error=>{
            this.setState({
                showWarning: true,
                alertMessage: 'There is a problem in processing your request'
            })
        })
    }
   
    renderResetView=()=>{
                return  <div className="mainWrap darkTheme"> 
                            <div className="formSignin">
                                <img className="logo" src={Logo} alt="" width="107"/>
                                {!this.state.resetPasswordSuccess?
                                <div className="formFields">
                                    <h6 className="text-white">Reset password</h6>
                                    <form className="pt-2">
                                        <div className="form-group">
                                            <label htmlFor="userName">Password</label>
                                            <input type="password" className="form-control" autoComplete="off" id="password" value={this.state.password} onChange={this.handlePassword1}/>  
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="userName">Confirm Password</label>
                                            <input type="password" className="form-control" autoComplete="off" id="confirm_password" value={this.state.confirmPassword} onChange={this.handlePassword2}/>  
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col mb-4">
                                                <button type="submit" className="float-right btn btn-primary btnStyle1" onClick={this.submitForm}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                    {
                                        this.state.showWarning && <Alert title={this.state.alertMessage}/>
                                    } 
                                </div>
                                :
                                <div className="formFields pb-5">
                                   <div className="text text-white">
                                        <div className="text-center"><i class="fa fa-check rounded border p-2" aria-hidden="true" style={{fontSize:'48px'}}></i></div>
                                        <div><h5 className="text text-white text-primary p-2 mt-1">Password reset successful</h5></div>
                                        <div><p className="card-text text-white p-2" style={{ fontSize: 13}}>You have successfully reset the password for your Axon account</p></div>
                                    </div>  
                                </div>
                                }
                            </div>
                        </div>
            }
    render() {
        return(
            <div>
                {this.renderResetView()}
            </div>
        )} 
    }

export default ResetView;



