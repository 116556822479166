import {
  RABBITMQ_BASE_URL
} from "../../config/api";
import {
  Client
} from "@stomp/stompjs";
import {
  CONFERENCE_ID,
  PARTICIPANT_ID,
  NODE_INDEX,
  PLAYER_SPG_ID,
  LAST_PLAYED_NODE
} from "../../Utils/constants";
import {
  lastNodeQuestion
} from "../learning_services/learningservice";

window.connection_status = 'connecting'

export const CONNECTION_STATUS = {
  NONE: '',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting'
};

export const NODE_TYPES = {
  QUESTION: "Question",
  INJECT: "Inject",
  NEXT_NODE: "next_node",
  LAST_NODE: "end_game"
};

const RABBITMQ_LOGIN = "guest";
const RABBITMQ_PASSWORD = "guest";

export default class RabbitMQQuestionsService {
  routerHistory;

  constructor(routerHistory) {
    this.routerHistory = routerHistory;
    this.initNetworkEvents();
  }

  connectToRabbitMQ() {
    if (window.WebSocket) {
      if (!window.rabbitMQClient) {
        window.connection_status = CONNECTION_STATUS.CONNECTING;
        window.rabbitMQClient = new Client();
        window.rabbitMQClient.reconnectDelay = 1000;
        window.rabbitMQClient.heartbeatIncoming = 1000;
        window.rabbitMQClient.heartbeatOutgoing = 1000;
        window.rabbitMQClient.configure({
          brokerURL: RABBITMQ_BASE_URL,
          connectHeaders: {
            login: RABBITMQ_LOGIN,
            passcode: RABBITMQ_PASSWORD
          },

          onConnect: () => {
            const conferenceId = localStorage.getItem(CONFERENCE_ID);
            console.log("onConnect");
            window.connection_status = CONNECTION_STATUS.CONNECTED;

            this.getLastQuestion();
            const TOPIC_NAME = `/exchange/participants/${conferenceId}`;
            window.rabbitMQClient.subscribe(`${TOPIC_NAME}`, message => {
              console.log("Connected", message);

              try {
                console.log("#messages", JSON.parse(message.body));
                const question = JSON.parse(message.body);
                this.goToQuestionsScreen(question);
              } catch (error) {
                console.log(error);
              }
            }, (err) => console.log(err));
          },
          // Helps during debugging, remove in production
          // debug: str => {
          // },
          onDisconnect: (err) => {
            window.connection_status = CONNECTION_STATUS.DISCONNECTED;
            console.log('onDisconnect', err);
          },
          onStompError: (err) => {
            window.connection_status = CONNECTION_STATUS.DISCONNECTED;
            console.log('onStompError', err);
          },
          onWebSocketError: (err) => {
            window.connection_status = CONNECTION_STATUS.DISCONNECTED;
            console.log('onWebSocketError', err);
          },
          onWebSocketClose: (err) => {
            window.connection_status = CONNECTION_STATUS.DISCONNECTED;
            console.log('onWebSocketClose', err);
          }
        });

        window.rabbitMQClient.activate();
      }
    } else {
      alert("Browser doesn't support WebSockets");
    }
  }

  initNetworkEvents() {
    console.log("Start Offline Event");
    window.addEventListener('offline', () => {
      console.log("User Is Offline");
      window.connection_status = CONNECTION_STATUS.DISCONNECTED;
      this.disconnect();
    }, false);
    window.addEventListener('online', () => {
      console.log("User Is Online");
      this.connectToRabbitMQ();
    }, false);
  }

  disconnect() {
    if (window.rabbitMQClient) {
      window.connection_status = CONNECTION_STATUS.DISCONNECTED;
      window.rabbitMQClient.deactivate();
      window.rabbitMQClient = undefined;
    }
  }

  getLastQuestion() {

    const participantId = localStorage.getItem(PARTICIPANT_ID);
    const conferenceId = localStorage.getItem(CONFERENCE_ID);
    console.log(participantId, conferenceId);
    lastNodeQuestion(participantId, conferenceId)
      .then(response => {
        console.log(response);
        console.log(response.data, response.data.hasOwnProperty('pushedAt'));
        if (response.data.hasOwnProperty('pushedAt')) {
          this.goToQuestionsScreen(response.data);
        }
        else if(response.data, response.data.illustration_apa_url){
         
          this.goToQuestionsScreen(response.data);
        }
        
      })
      .catch(error => console.log(error));
  }

  goToQuestionsScreen(question) {
    const {
      title,
      choices,
      node_index,
      player_spg_id,
      id,
      time_to_answer,
      type,
      illustration_apa_url
    } = question;
    localStorage.setItem(NODE_INDEX, node_index);
    // localStorage.setItem(PLAYER_SPG_ID, player_spg_id);
    console.log(question);
    if (type === NODE_TYPES.QUESTION) {
      this.routerHistory.replace({
        pathname: "/participant/question",
        state: {
          title,
          choices,
          node_index,
          player_spg_id,
          id,
          time_to_answer,
          timestamp: question.pushedAt ? new Date(question.pushedAt).getTime() : Date.now(),
          from: "WELCOME",
          type
        }
      });
    } else if (type === NODE_TYPES.INJECT) {
      this.routerHistory.replace({
        pathname: "/participant/question",
        state: {
          illustration_apa_url,
          type,
          from: "WELCOME"
        }
      });
    } else if (type === NODE_TYPES.NEXT_NODE || type === NODE_TYPES.LAST_NODE) {
      if (type === NODE_TYPES.LAST_NODE) {
        localStorage.removeItem(LAST_PLAYED_NODE);
        localStorage.removeItem(NODE_INDEX);
        localStorage.removeItem(PLAYER_SPG_ID);
      }
      this.routerHistory.replace({
        pathname: '/participant/thank-you',
        state: {
          from: type
        }
      });
    }
  }
}