import React, { useState, useEffect } from "react";
import { CONNECTION_STATUS } from "../../services/rabbitmq_services";
import "./index.css";

function ConnectionStatus() {
    const [status, setStatus] = useState(CONNECTION_STATUS.CONNECTING);
    let statusInterval = null;

    useEffect(() => {
        statusInterval = setInterval(() => {
            if(status != window.connection_status){
                setStatus(window.connection_status);
            }
           }, 1000)
    }, []);

    useEffect(() => {
        return () => {
          clearInterval(statusInterval);
        }
      }, []);

    return (<span className="d-flex align-items-center text-capitalize">
                <span 
                >
                    <figure  
                    className= {`large-dot mr-1 circle ${
                        (status === CONNECTION_STATUS.CONNECTED) ? CONNECTION_STATUS.CONNECTED :
                        (status === CONNECTION_STATUS.DISCONNECTED) ? CONNECTION_STATUS.DISCONNECTED :
                        (status === CONNECTION_STATUS.CONNECTING) ? CONNECTION_STATUS.CONNECTING: ''
                        }`}></figure>

                </span>
                {status}
            </span>);
}

export default ConnectionStatus;