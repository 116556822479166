import React from 'react';
import ReactDOM from 'react-dom';
import './assets/stylesheets/index.css';
import './assets/stylesheets/App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import './assets/stylesheets/normalize.css';
import './assets/stylesheets/main.css';

import registerServiceWorker from './registerServiceWorker';

import App from './App';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

ReactDOM.render(<App/>, document.getElementById('root'));
registerServiceWorker();

