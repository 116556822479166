import React, { Component, Fragment } from "react";
import "./index.scss";
import { withRouter } from "react-router";
import RabbitMQQuestionsService, {
  NODE_TYPES
} from "../../../services/rabbitmq_services";

class ThankYouScreen extends Component {

  componentDidMount() {
    const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
    rabbitMQService.connectToRabbitMQ();
  }

  thankYouTemplate() {
    return (
      <Fragment>
        <div className="top-background-image">
          <img
            alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <div className="thank-you-container">
          <img
            height="100"
            className="mb-3"
            alt="tick-mark"
            src={process.env.PUBLIC_URL + "/images/tick_mark.png"}
          />
          <p className="text-center">Thank you for the participation.</p>
        </div>
      </Fragment>
    );
  }

  waitingTemplate() {
    return (<Fragment>
        <div className="top-background-image">
          <img
            alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <div className="thank-you-container">
          <img
            height="80"
            className="mb-3"
            alt="tick-mark"
            src={process.env.PUBLIC_URL + "/images/sand_clock.png"}
          />
          <p className="text-center">Thank you. Please wait for the next node.</p>
        </div>
      </Fragment>
    );
  }

  lastNodeTemplate() {
    setTimeout(() => {
      this.props.history.push({ pathname: '/participant/welcome' });
    }, 4000);
    return (
      <Fragment>
        <div className="top-background-image">
          <img
            alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
          />
        </div>
        <div className="thank-you-container">
          <img
            height="100"
            className="mb-3"
            alt="tick-mark"
            src={process.env.PUBLIC_URL + "/images/tick_mark.png"}
          />
          <p className="text-center">Thank you for the participation. The session is completed.</p>
        </div>
      </Fragment>
    );
  }

  renderMessageTemplate() {
    const { from } = this.props.location.state;
    if (from === NODE_TYPES.NEXT_NODE) {
      return this.waitingTemplate();
    } else if (from === NODE_TYPES.LAST_NODE) {
      return this.lastNodeTemplate();
    } else {
      return this.thankYouTemplate();
    }
  }

  render() {
    return (
      <div>
        {/* {this.renderRedirect()} */}
        {this.renderMessageTemplate()}
      </div>
    );
  }
}

export default withRouter(ThankYouScreen);
