import React, { Component } from "react";
import "./index.scss";
import RabbitMQQuestionsService from "../../../services/rabbitmq_services";

class LogoutScreen extends Component {
  componentDidMount() {
    setTimeout(() => {
      const rabbitMQService = new RabbitMQQuestionsService(this.props.history);
      rabbitMQService.disconnect();
      // localStorage.clear();
      this.props.history.push("/join");
    }, 1000);
  }

  render() {
    return (
      <div>
        <div className="top-background-image">
          <img
            alt="axon"
            height="40"
            src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
            
          />
        </div>
        <div className="logout-container">
          <img
            height="100"
            className="mb-3"
            alt="tick-mark"
            src={process.env.PUBLIC_URL + "/images/tick_mark.png"}
          />
          <p className="text-center">You've been logged out successfully</p>
        </div>
      </div>
    );
  }
}

export default LogoutScreen;
