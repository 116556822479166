import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.scss";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import {
  ListItem,
  ListItemText,
  withStyles,
  ListItemIcon,
  IconButton
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { AUTH_TOKEN, PARTICIPANT_ID, CONFERENCE_ID } from "../../../Utils/constants";
import MenuIcon from "@material-ui/icons/Menu";
import { RouteWithSubRoutes } from "../../../App";
import ConnectionStatus from "../../../components/ConnectionStatus";

const classes = {
  list: {
    width: 250
  }
};

class SideNavScreen extends React.Component {
  state = {
    isMenuOpen: false
  };

  toggleDrawer = isMenuOpen => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ isMenuOpen });
  };

  logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(PARTICIPANT_ID);
    localStorage.removeItem(CONFERENCE_ID);
    this.props.history.push("/logout");
  };

  render() {
    const { classes, routes } = this.props;
    return (
      <Router>
        <SwipeableDrawer
          open={this.state.isMenuOpen}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div className="nav-top-container">
            <img
              height="32"
              alt="axon"
              src={process.env.PUBLIC_URL + "/images/axon_logo.png"}
            />
            <Icon onClick={this.toggleDrawer(false)} className="close-icon">
              close
            </Icon>
          </div>
          <div
            role="presentation"
            className={classes.list}
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            <List>
              <ListItem
                button
                {...{ to: "/participant/welcome" }}
                component={Link}
              >
                <ListItemIcon>
                  <Icon>home</Icon>
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>

              <ListItem
                button
                {...{ to: "/participant/userProfile" }}
                component={Link}
              >
                <ListItemIcon>
                  <Icon>person_outline</Icon>
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button onClick={this.logout}>
                <ListItemIcon>
                  <Icon>power_settings_new</Icon>
                </ListItemIcon>

                <ListItemText primary="Exit" />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>

        <div className="menu-button px-4 py-2">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <ConnectionStatus />
        </div>

        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Router>
    );
  }
}

export default withStyles(classes)(SideNavScreen);
